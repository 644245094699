import React from 'react';

const PostIcon = ({ size = 20, color = "currentColor" }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 150 150" 
    width={size} 
    height={size} 
    fill={color}
  >
    <path d="M131.25 6.60L18.75 6.60Q13.65 6.60 10.13 10.13Q6.60 13.65 6.60 18.75L6.60 131.25Q6.60 136.35 10.13 139.88Q13.65 143.40 18.75 143.40L131.25 143.40Q136.35 143.40 139.88 139.88Q143.40 136.35 143.40 131.25L143.40 18.75Q143.40 13.65 139.88 10.13Q136.35 6.60 131.25 6.60ZM134.10 18.75L134.10 131.25Q134.10 132.45 133.28 133.28Q132.45 134.10 131.25 134.10L18.75 134.10Q17.55 134.10 16.72 133.28Q15.90 132.45 15.90 131.25L15.90 18.75Q15.90 17.55 16.72 16.72Q17.55 15.90 18.75 15.90L131.25 15.90Q132.45 15.90 133.28 16.72Q134.10 17.55 134.10 18.75ZM45 53.40L45 44.10L105 44.10L105 53.40L45 53.40ZM45 105.90L45 96.60L105 96.60L105 105.90L45 105.90ZM45 79.65L45 70.35L105 70.35L105 79.65L45 79.65Z"/>
  </svg>
);

export default PostIcon;