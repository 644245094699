import React from 'react';

const NSFWIcon = ({ size = 20, color = "currentColor" }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 150 150" 
    width={size} 
    height={size} 
    fill={color}
  >
    <path d="M137.55 50.40L99.60 12.45Q92.85 5.70 83.92 3.38Q75 1.05 66.08 3.38Q57.15 5.70 50.40 12.45L12.45 50.40Q5.70 57.15 3.38 66.08Q1.05 75 3.38 83.92Q5.70 92.85 12.45 99.60L50.55 137.55Q57.15 144.30 66.08 146.63Q75 148.95 83.92 146.63Q92.85 144.30 99.60 137.55L137.55 99.60Q144.30 92.85 146.63 83.92Q148.95 75 146.63 66.08Q144.30 57.15 137.55 50.40ZM130.95 92.85L92.85 130.95Q87.90 135.60 81.45 137.25Q75 138.90 68.55 137.25Q62.10 135.60 57.15 130.95L19.05 92.85Q15.45 89.40 13.57 84.75Q11.70 80.10 11.70 75Q11.70 69.90 13.57 65.25Q15.45 60.60 19.05 57.15L57.15 19.05Q60.60 15.45 65.25 13.58Q69.90 11.70 75 11.70Q80.10 11.70 84.75 13.58Q89.40 15.45 92.85 19.05L130.95 57.15Q134.55 60.60 136.42 65.25Q138.30 69.90 138.30 75Q138.30 80.10 136.42 84.75Q134.55 89.40 130.95 92.85ZM53.10 43.95L33.15 53.40L33.15 63.60L45.75 57.60L45.75 108.75L56.40 108.75L56.40 43.95L53.10 43.95ZM100.80 74.40Q104.10 72 106.05 68.33Q108 64.65 107.85 60.60Q108 55.80 105.38 51.67Q102.75 47.55 98.25 45.45Q93.75 43.35 88.58 43.35Q83.40 43.35 78.90 45.45Q74.40 47.55 71.77 51.60Q69.15 55.65 69.30 60.45Q69.15 64.65 71.10 68.33Q73.05 72 76.50 74.40Q71.70 76.80 68.77 81.08Q65.85 85.35 65.85 90.53Q65.85 95.70 68.92 100.13Q72 104.55 77.10 106.95Q82.50 109.50 88.65 109.50Q94.80 109.50 100.20 106.95Q105.15 104.55 108.30 100.05Q111.30 95.70 111.30 90.53Q111.30 85.35 108.38 81.08Q105.45 76.80 100.80 74.40ZM80.10 56.55Q81.45 54.45 83.70 53.25Q85.95 52.05 88.58 52.05Q91.20 52.05 93.52 53.25Q95.85 54.45 97.13 56.63Q98.40 58.80 98.40 61.28Q98.40 63.75 97.05 65.92Q95.70 68.10 93.45 69.38Q91.20 70.65 88.50 70.65Q85.80 70.65 83.55 69.38Q81.30 68.10 80.10 65.92Q78.90 63.75 78.90 61.20Q78.90 58.65 80.10 56.55ZM99.15 95.40Q97.50 97.80 94.65 99.15Q91.80 100.50 88.65 100.50Q85.50 100.50 82.65 99.22Q79.80 97.95 78.15 95.47Q76.50 93 76.50 90.08Q76.50 87.15 78.08 84.75Q79.65 82.35 82.50 80.92Q85.35 79.50 88.58 79.50Q91.80 79.50 94.65 80.92Q97.50 82.35 99.15 84.83Q100.80 87.30 100.80 90.15Q100.80 93 99.15 95.40Z"/>
  </svg>
);

export default NSFWIcon;