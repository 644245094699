import React, { useState, useMemo, useEffect } from 'react';
import { Filter, Shuffle } from 'lucide-react';
import RedditCard from './ui/RedditCard';
import TweetCard from './ui/TweetCard';
import FilterPopover from './ui/FilterPopover';
import './TwitterSearch.css';
import { useLocation } from 'react-router-dom';

const TWEETS_PER_PAGE = 10;

const TwitterSearch = ({ allTweets, darkMode }) => {
  const [displayedTweets, setDisplayedTweets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    likes: true,
    bookmarks: true,
    user_tweets: false,
    reddit: false,
    withImages: true,
    withVideos: true,
    textOnly: true,
    over18: false,
    onlyOver18: false,
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get('q');
    if (searchQuery) {
      setSearchTerm(decodeURIComponent(searchQuery));
    }
  }, [location.search]);

  const filteredTweets = useMemo(() => {
    return allTweets.filter((tweet) => {
      // Parse search operators
      const parseSearch = (searchTerm) => {
        const terms = [];
        const operators = {
          from: null,
          subreddit: null,
          is: null,
        };
      
        // Match either operators in parentheses or regular terms
        const regex = /\(([a-zA-Z]+:[^\)]+)\)|("[^"]+"|[^\s]+)/g;
        let match;
      
        while ((match = regex.exec(searchTerm)) !== null) {
          const [fullMatch, operatorMatch, term] = match;
          
          if (operatorMatch) {
            // Handle operator in parentheses
            const [operator, value] = operatorMatch.split(':');
            operators[operator] = value;
          } else if (term) {
            // Handle regular terms and OR terms
            const cleanTerm = term.replace(/"/g, '');
            // Split on pipe if it exists, otherwise use the term as is
            const termParts = cleanTerm.split('|');
            terms.push(...termParts); // Spread operator to flatten OR terms into the array
          }
        }
      
        return { terms, operators };
      };
      
      const { terms, operators } = parseSearch(searchTerm);
      const searchTerms = terms.map(term => term.toLowerCase());

      // Check if text matches any of the non-operator search terms
      const textMatches = searchTerms.length === 0 || searchTerms.some(term =>
        (tweet.text || tweet.title || '').toLowerCase().includes(term.toLowerCase())
      );
      

      // Check operator matches
      const fromMatch = !operators.from ||
        (tweet.user?.screen_name || tweet.user?.handle || '').toLowerCase() === operators.from.toLowerCase() ||
        (tweet.user?.name || '').toLowerCase() === operators.from.toLowerCase();

      const subredditMatch = !operators.subreddit ||
        (tweet.source === 'reddit' && tweet.subreddit?.display_name?.toLowerCase() === operators.subreddit.toLowerCase());

      const typeMatch = !operators.is || (
        operators.is === 'reddit' ? tweet.source === 'reddit' :
          operators.is === 'tweet' ? ['like', 'bookmark', 'user_tweet'].includes(tweet.source) :
            operators.is === 'like' ? tweet.source === 'like' :
              operators.is === 'bookmark' ? tweet.source === 'bookmark' :
                true
      );

      // Debug the search matching
      if (operators.subreddit && tweet.source === 'reddit') {
        console.log('Checking subreddit match:', {
          searchFor: operators.subreddit.toLowerCase(),
          tweetSubreddit: (tweet.subreddit || '').toLowerCase(),
          matches: (tweet.subreddit || '').toLowerCase() === operators.subreddit.toLowerCase()
        });
      }

      const matchesSearch = textMatches && fromMatch && subredditMatch && typeMatch;

      const matchesSource =
        (filters.likes && tweet.source === 'like') ||
        (filters.bookmarks && tweet.source === 'bookmark') ||
        (filters.user_tweets && tweet.source === 'user_tweet') ||
        (filters.reddit && tweet.source === 'reddit');

      const hasMedia = tweet.medias && tweet.medias.length > 0;
      const hasImages = hasMedia && tweet.medias.some(media => media.type === 'photo');
      const hasVideos = hasMedia && tweet.medias.some(media => media.type === 'video');

      const matchesMedia =
        (filters.withImages && hasImages) ||
        (filters.withVideos && hasVideos) ||
        (filters.textOnly && !hasMedia);

      const isOver18 = tweet.over_18 ?? false;

      const matchesAdultContent =
        (filters.onlyOver18 && isOver18) ||
        (!filters.onlyOver18 && (filters.over18 || !isOver18));

      return matchesSearch && matchesSource && (tweet.source === 'reddit' || matchesMedia) && matchesAdultContent;
    });
  }, [allTweets, searchTerm, filters]);

  useEffect(() => {
    setDisplayedTweets(filteredTweets.slice(0, TWEETS_PER_PAGE));
    setCurrentPage(1);
  }, [filteredTweets]);

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    const nextTweets = filteredTweets.slice(0, nextPage * TWEETS_PER_PAGE);
    setDisplayedTweets(nextTweets);
    setCurrentPage(nextPage);
  };

  const handleShuffle = () => {
    const shuffled = [...filteredTweets].sort(() => 0.5 - Math.random());
    setDisplayedTweets(shuffled.slice(0, TWEETS_PER_PAGE));
    setCurrentPage(1);
  };

  const renderTweet = (tweet) => {
    if (!tweet) return null;

    if (tweet.source === 'reddit') {
      return <RedditCard key={tweet.id} post={tweet} />;
    }

    return <TweetCard key={tweet.id} tweet={tweet} />;
  };

  return (
    <div className={`twitter-search mt-8 ${darkMode ? 'dark-mode' : ''}`}>
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search (try: (from:user) (subreddit:name) (is:type))"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
          title="Search operators (must be in parentheses):
• (from:username) - Find content from specific user
• (subreddit:name) - Find posts from specific subreddit
• (is:type) - Filter by type (reddit/tweet/like/bookmark)
• Use quotes for exact phrases"
        />
        <button
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          className="filter-button"
          aria-label="Open filters"
        >
          <Filter size={20} />
        </button>
        {isFilterOpen && (
          <FilterPopover
            filters={filters}
            setFilters={setFilters}
            onClose={() => setIsFilterOpen(false)}
          />
        )}
      </div>
      <p className="mb-4 subtitle">
        Explore & rediscover my liked and bookmarked tweets as well as my Reddit saves!
      </p>
      <p className="mb-4 total-tweets">
        Total saved items: <span className="font-italic">{allTweets.length}</span> (
        Likes: <span className="font-italic">{allTweets.filter(t => t.source === 'like').length}</span>,
        Bookmarks: <span className="font-italic">{allTweets.filter(t => t.source === 'bookmark').length}</span>,
        User Tweets: <span className="font-italic">{allTweets.filter(t => t.source === 'user_tweet').length}</span>,
        Reddit: <span className="font-italic">{allTweets.filter(t => t.source === 'reddit').length}</span>)
      </p>
      {searchTerm && (
        <p className="mb-4 search-results">
          Found {filteredTweets.length} results for "{searchTerm}"
        </p>
      )}
      <div className="mb-4">
        <button onClick={handleShuffle} className="shuffle-button">
          <Shuffle className="inline-block mr-2" size={20} />
          Shuffle Saves
        </button>
      </div>
      <div>{displayedTweets.map(renderTweet)}</div>
      {displayedTweets.length < filteredTweets.length && (
        <button
          onClick={handleLoadMore}
          className="load-more-button"
        >
          Load More
        </button>
      )}
    </div>
  );
};

export default TwitterSearch;