import React from 'react';

const UpvoteIcon = ({ size = 20, color = "currentColor" }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 150 150" 
    width={size} 
    height={size} 
    fill={color}
  >
    <path d="M97.50 143.40L52.50 143.40Q49.05 143.40 46.57 140.93Q44.10 138.45 44.10 135L44.10 83.40L15.90 83.40Q13.50 83.40 11.40 82.05Q9.30 80.70 8.25 78.38Q7.20 76.05 7.57 73.58Q7.95 71.10 9.60 69.30L68.70 3.75Q71.25 1.05 75 1.05Q78.75 1.05 81.30 3.75L140.40 69.30Q142.05 71.10 142.42 73.58Q142.80 76.05 141.82 78.38Q140.85 80.70 138.75 82.05Q136.65 83.40 134.10 83.40L105.90 83.40L105.90 135Q105.90 138.45 103.42 140.93Q100.95 143.40 97.50 143.40ZM53.40 73.95L53.40 134.10L96.60 134.10L96.60 74.10L132 73.95L75 10.80L18 73.95L53.40 73.95Z"/>
  </svg>
);

export default UpvoteIcon;