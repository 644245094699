// components/ui/SearchLink.jsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from '../utils';
const SearchLink = ({ 
  operator, 
  value, 
  children, 
  className,
  showParens = true, // Option to hide parentheses in display but keep in search
  pureSearch = false // option to just have it be a regular search
}) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    let searchQuery;
    if (pureSearch) {
      searchQuery = value;
    } else {
      searchQuery = showParens ? `(${operator}:${value})` : `${operator}:${value}`;
    }
    navigate(`/?q=${encodeURIComponent(searchQuery)}`);
  };

  return (
    <button
      onClick={handleClick}
      className={cn(
        "text-twitter-blue hover:underline focus:outline-none focus:ring-2 focus:ring-twitter-blue/20 rounded",
        className
      )}
    >
      {children || (showParens ? `(${operator}:${value})` : value)}
    </button>
  );
};

export default SearchLink;