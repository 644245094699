import React from 'react';

const DownvoteIcon = ({ size = 20, color = "currentColor" }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 150 150" 
    width={size} 
    height={size} 
    fill={color}
  >
    <path d="M75 149.10Q71.25 149.10 68.70 146.25L9.60 80.70Q7.95 78.90 7.57 76.42Q7.20 73.95 8.17 71.63Q9.15 69.30 11.25 67.95Q13.35 66.60 15.90 66.60L44.10 66.60L44.10 15Q44.10 11.55 46.57 9.08Q49.05 6.60 52.50 6.60L97.50 6.60Q100.95 6.60 103.42 9.08Q105.90 11.55 105.90 15L105.90 66.60L134.10 66.60Q136.50 66.60 138.60 67.95Q140.70 69.30 141.75 71.63Q142.80 73.95 142.42 76.42Q142.05 78.90 140.40 80.70L81.30 146.25Q78.75 149.10 75 149.10ZM53.40 75.90L18 76.05L75 139.20L132 76.05L96.60 76.05L96.60 15.90L53.40 15.90L53.40 75.90Z"/>
  </svg>
);

export default DownvoteIcon;