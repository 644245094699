// components/TopicClusters.jsx
import React from 'react';
import { Card, CardContent } from './card';
import { ChevronRight } from 'lucide-react';
import { cn } from '../utils';

const TopicCard = ({ cluster, id, isSelected, onClick }) => (
  <div
    className={cn(
      "border rounded-lg p-4 cursor-pointer transition-all duration-200",
      isSelected 
        ? "border-twitter-blue ring-2 ring-twitter-blue/20" 
        : "border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700"
    )}
    onClick={onClick}
  >
    <div className="flex justify-between items-start">
      <div className="flex-1">
        <h4 className="font-semibold mb-2">
          Topic: {cluster.topic_label}
        </h4>
        <ul className="list-disc list-inside mb-3">
          {cluster.topic_terms.slice(0, 5).map((term, index) => (
            <li key={index} className="text-gray-600 dark:text-gray-400">{term}</li>
          ))}
        </ul>
        <div className="flex items-center text-sm">
          <span className="text-gray-500 dark:text-gray-400">
            {cluster.size} tweets
          </span>
          <span className="text-twitter-blue ml-2 flex items-center group-hover:translate-x-0.5 transition-transform">
            View tweets <ChevronRight className="w-4 h-4 ml-1" />
          </span>
        </div>
      </div>
    </div>
  </div>
);

const TopicClusters = ({ clusters, selectedTopic, onTopicSelect }) => {
  const validClusters = Object.entries(clusters)
    .filter(([id, cluster]) => 
      id !== '-1' && 
      cluster.topic_label && 
      !cluster.topic_label.includes('unknown')
    );

  return (
    <Card className="mb-6 insight-topics">
      <CardContent>
        <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">Topic Clusters</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {validClusters.map(([id, cluster]) => (
            <TopicCard
              key={id}
              id={id}
              cluster={cluster}
              isSelected={selectedTopic === id}
              onClick={() => onTopicSelect(id)}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default TopicClusters;