// components/TopicTweetsModal.jsx
import React, { useEffect, useState } from 'react';
import { X, ArrowLeft, ArrowRight } from 'lucide-react';
import TweetCard from './TweetCard';

const TWEETS_PER_PAGE = 10;

const TopicTweetsModal = ({
  isOpen,
  onClose,
  topic,
  allTweets
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    if (isOpen) {
      // Prevent body scroll when modal is open
      document.body.style.overflow = 'hidden';
      setAnimationClass('animate-in slide-in-from-bottom');
    } else {
      document.body.style.overflow = 'unset';
      setAnimationClass('animate-out slide-out-to-bottom');
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen || !topic) return null;

  const tweets = topic.tweet_refs
    .map(ref => allTweets.find(t => t.id === ref.id))
    .filter(Boolean);

  const totalPages = Math.ceil(tweets.length / TWEETS_PER_PAGE);
  const currentTweets = tweets.slice(
    (currentPage - 1) * TWEETS_PER_PAGE,
    currentPage * TWEETS_PER_PAGE
  );

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <div className="fixed inset-0 z-50">
      {/* Backdrop */}
      <div
        className="fixed inset-0 bg-black/40 backdrop-blur-sm transition-all duration-300"
        onClick={onClose}
      />

      {/* Modal */}
      <div
        className={`fixed inset-0 sm:inset-4 max-w-4xl mx-auto bg-white dark:bg-gray-800 
          shadow-xl rounded-none sm:rounded-lg overflow-hidden ${animationClass}`}
      >
        {/* Header */}
        <div className="sticky top-0 z-10 flex items-center justify-between p-4 border-b 
          border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
          <div>
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
              <b>Cluster:</b> <i>{topic.topic_label}</i>
            </h2>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {tweets.length} tweets in this topic
            </p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full
              transition-colors duration-200"
          >
            <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </button>
        </div>

        {/* Content */}
        <div className="p-4 overflow-y-auto h-[calc(100%-4rem)]">
          <div className="space-y-4">
            {currentTweets.map(tweet => (
              <TweetCard key={tweet.id} tweet={tweet} />
            ))}
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="sticky bottom-0 flex items-center justify-between mt-6 p-4 
              border-t border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 
                  dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 
                  dark:border-gray-600 rounded-md hover:bg-gray-50 dark:hover:bg-gray-700
                  disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                Previous
              </button>
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 
                  dark:text-gray-200 bg-white dark:bg-gray-800 border border-gray-300 
                  dark:border-gray-600 rounded-md hover:bg-gray-50 dark:hover:bg-gray-700
                  disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Next
                <ArrowRight className="w-4 h-4 ml-2" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopicTweetsModal;