import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import TwitterSearch from './components/TwitterSearch';
import Insights from './components/Insights';
import TabbedNavigation from './components/TabbedNavigation';
import ErrorBoundary from './ErrorBoundary';
import { Moon, Sun } from 'lucide-react';
import InsightsIcon from './components/ui/InsightsIcon';
import './App.css';

const TwitterIcon = ({ size = 24, color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill={color}>
    <path d="M23.954 4.569c-.885.392-1.83.656-2.825.775 1.014-.608 1.794-1.569 2.163-2.724-.951.564-2.005.974-3.127 1.195-.897-.959-2.178-1.559-3.594-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124-4.09-.205-7.719-2.165-10.148-5.144-.424.729-.666 1.576-.666 2.476 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.23-.616v.062c0 2.385 1.693 4.374 3.946 4.827-.413.112-.849.171-1.296.171-.317 0-.626-.03-.927-.086.631 1.953 2.445 3.376 4.604 3.416-1.68 1.319-3.809 2.107-6.115 2.107-.398 0-.79-.023-1.175-.069 2.179 1.397 4.768 2.212 7.548 2.212 9.051 0 14-7.496 14-13.986 0-.21 0-.423-.015-.634.961-.695 1.8-1.562 2.46-2.549z" />
  </svg>
);

const AppContent = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved !== null ? JSON.parse(saved) : false;
  });
  const [allTweets, setAllTweets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    // Update both body and html tag
    if (darkMode) {
      document.documentElement.classList.add('dark');
      document.body.classList.add('dark-mode');
    } else {
      document.documentElement.classList.remove('dark');
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  const decodeHTMLEntities = (text) => {
    if (!text) return '';
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  const loadTweets = useCallback(async () => {
    const LIKES_FILE = '/exports/likes/merged_tweets.json';
    const BOOKMARKS_FILE = '/exports/bookmarks/bookmarks.json';
    const USER_TWEETS_FILE = '/exports/user_tweets/20241015_user_tweets.json';
    const REDDIT_SAVES_FILE = '/exports/reddit/reddit-export.json';

    const fetchAndParse = async (url, name) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();
        try {
          return JSON.parse(text);
        } catch (e) {
          console.error(`Failed to parse ${name} JSON:`, text.slice(0, 100));
          throw new Error(`Invalid JSON in ${name}`);
        }
      } catch (error) {
        console.error(`Error fetching ${name}:`, error);
        throw error;
      }
    };

    try {
      const [likesData, bookmarksData, userTweetsData, redditData] = await Promise.all([
        fetchAndParse(LIKES_FILE, 'likes'),
        fetchAndParse(BOOKMARKS_FILE, 'bookmarks'),
        fetchAndParse(USER_TWEETS_FILE, 'user tweets'),
        fetchAndParse(REDDIT_SAVES_FILE, 'reddit saves')
      ]);

      // if (!likesResponse.ok || !bookmarksResponse.ok || !userTweetsResponse.ok || !redditResponse.ok) {
      //   throw new Error(`Failed to load data: ${!likesResponse.ok ? 'likes ' : ''}${!bookmarksResponse.ok ? 'bookmarks ' : ''}${!userTweetsResponse.ok ? 'user tweets ' : ''}${!redditResponse.ok ? 'reddit' : ''}`);
      // }

      // const likesData = await likesResponse.json();
      // const bookmarksData = await bookmarksResponse.json();
      // const userTweetsData = await userTweetsResponse.json();      
      // const redditData = await redditResponse.json();
      
      const processedLikes = likesData
        .filter(tweet => !tweet.skip)
        .map(tweet => ({
          ...tweet,
          text: decodeHTMLEntities(tweet.full_text || tweet.text || ''),
          source: 'like',
          quote_status: tweet.quoted_status ? {
            ...tweet.quoted_status,
            text: decodeHTMLEntities(tweet.quoted_status.full_text || tweet.quoted_status.text || '')
          } : null
        }));

      const processedBookmarks = bookmarksData.data.map(bookmark => ({
        id: bookmark.id,
        text: decodeHTMLEntities(bookmark.text),
        created_at: bookmark.date,
        user: {
          name: bookmark.user.name,
          screen_name: bookmark.user.handle,
          profile_image_url: bookmark.user.profilePicUrl
        },
        url: bookmark.url,
        medias: bookmark.media,
        source: 'bookmark',
        retweet_count: bookmark.retweet_count,
        reply_count: bookmark.reply_count,
        quote_status: bookmark.quote_status ? {
          ...bookmark.quote_status,
          text: decodeHTMLEntities(bookmark.quote_status.text)
        } : null
      }));

      const processedUserTweets = userTweetsData.map(tweet => ({
        id: tweet.id,
        text: decodeHTMLEntities(tweet.full_text),
        created_at: tweet.date,
        user: {
          name: tweet.user.name,
          screen_name: tweet.user.screen_name,
          profile_image_url: tweet.user.profile_image_url
        },
        url: tweet.url,
        medias: tweet.media,
        source: 'user_tweet',
        retweet_count: tweet.retweet_count,
        reply_count: tweet.reply_count,
        quote_status: tweet.quote_status ? {
          ...tweet.quote_status,
          text: decodeHTMLEntities(tweet.quote_status.full_text)
        } : null
      }));
      
      const processRedditItem = (item, index = 0) => ({
        id: item.id,
        title: item.title || '',
        text: item.selftext || item.body || '',
        created_at: new Date(item.created_utc * 1000).toISOString(),
        author: item.author?.name || '[deleted]',
        subreddit: item.subreddit.display_name,
        url: `https://new.reddit.com${item.permalink}`,
        ups: item.ups,
        downs: item.downs,
        num_comments: item.num_comments || 0,
        source: 'reddit',
        thumbnail: item.thumbnail,
        is_self: item.is_self,
        over_18: item.over_18,
        _replies: item._replies,
        index: index
      });

      const processedRedditSaves = redditData.saved
        .filter(post => !post.over_18)
        .flatMap((post, postIndex) => {
          const processedPost = processRedditItem(post);
          if (post.comments) {
            const processedComments = post.comments
              .filter(comment => !comment.over_18)
              .map((comment, commentIndex) =>
                processRedditItem(comment, commentIndex + 1)
              );
            return [processedPost, ...processedComments];
          }
          return [processedPost];
        });

        const mergedData = [...processedLikes, ...processedBookmarks, ...processedUserTweets, ...processedRedditSaves];
        // shuffle data on load
        const shuffledData = [...mergedData].sort(() => 0.5 - Math.random());
        setAllTweets(shuffledData);
        setIsLoading(false);
      } catch (err) {
        console.error('Failed to load data:', err);
        setError(`Failed to load data: ${err.message}`);
        setIsLoading(false);
      }
    }, []);

  useEffect(() => {
    loadTweets();
  }, [loadTweets]);

  const isInsightsPage = location.pathname === '/insights';

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="app-container">
      <div className="container mx-auto p-4 max-w-2xl">
        <header className="flex flex-col sm:flex-row justify-between items-center mb-4">
          <div className="flex items-center mb-2 sm:mb-0">
            <span className="text-[#1DA1F2] mr-2">
              {isInsightsPage ? (
                <InsightsIcon size={24} color={darkMode ? "#ffffff" : "#1DA1F2"} />
              ) : (
                <TwitterIcon size={24} color={darkMode ? "#ffffff" : "#1DA1F2"} />
              )}
            </span>
            <TabbedNavigation />
          </div>
          <button onClick={toggleDarkMode} className="dark-mode-toggle">
            {darkMode ? <Sun className="text-yellow-400" /> : <Moon className="text-gray-600" />}
          </button>
        </header>
        {isLoading ? (
          <div className="text-center">Loading tweets...</div>
        ) : (
          <Routes>
            <Route path="/" element={<TwitterSearch allTweets={allTweets} darkMode={darkMode} />} />
            <Route path="/insights" element={<Insights allTweets={allTweets} />} />
          </Routes>
        )}
      </div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <AppContent />
      </ErrorBoundary>
    </Router>
  );
}

export default App;