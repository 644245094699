import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cn } from "./utils";

const TabbedNavigation = () => {
  const location = useLocation();

  const tabs = [
    { name: 'Saves Search', path: '/' },
    { name: 'Insights', path: '/insights' },
  ];

  return (
    <nav className="flex" aria-label="Tabs">
      {tabs.map((tab) => (
        <Link
          key={tab.name}
          to={tab.path}
          className={cn(
            "px-3 py-2  font-medium",
            location.pathname === tab.path
              ? 'text-twitter-blue border-b-2 border-twitter-blue'
              : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
          )}
        >
          {tab.name}
        </Link>
      ))}
    </nav>
  );
};

export default TabbedNavigation;