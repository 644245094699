import React, { useState } from 'react';

const SkeletonProfileImage = ({ className }) => (
  <div className={`skeleton-profile-image animate-pulse bg-gray-200 dark:bg-gray-700 ${className}`}></div>
);

const ProfileImage = ({ src, alt, userHandle, className }) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [errorCount, setErrorCount] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
    
  const handleError = () => {
    setErrorCount(prevCount => prevCount + 1);
    if (errorCount === 0) {
      // If Twitter's default also fails, use a local fallback
      setImageSrc('/default-profile-pic.png');
    }
    // If the local fallback also fails, it will just show the broken image icon
  };

  const handleLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className={`relative ${className}`}>
      {!imageLoaded && <SkeletonProfileImage className="absolute inset-0" />}
      <img
        src={imageSrc}
        alt={alt}
        className={`w-full h-full object-cover rounded-full ${imageLoaded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
        onError={handleError}
        onLoad={handleLoad}
      />
    </div>
  );
};

export default ProfileImage;