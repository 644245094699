import React from 'react';
import { X } from 'lucide-react';
import './FilterPopover.css';

const FilterPopover = ({ filters, setFilters, onClose }) => {
  const handleFilterChange = (filterKey) => {
    if (filterKey === 'onlyOver18') {
      setFilters(prev => ({ ...prev, [filterKey]: !prev[filterKey], over18: false }));
    } else if (filterKey === 'over18') {
      setFilters(prev => ({ ...prev, [filterKey]: !prev[filterKey], onlyOver18: false }));
    } else {
      setFilters(prev => ({ ...prev, [filterKey]: !prev[filterKey] }));
    }
  };
  
  const renderFilterOption = (key, label, value) => (
    <label key={key} className="flex items-center group cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          checked={value}
          onChange={() => handleFilterChange(key)}
          className="sr-only"
        />
        <div className="twitter-checkbox bg-white dark:bg-gray-700 border-2 border-gray-300 dark:border-gray-600 rounded-full w-5 h-5 focus:outline-none transition-all duration-200 ease-in-out group-hover:border-twitter-blue dark:group-hover:border-twitter-blue-dark">
          {value && (
            <svg className="fill-current text-twitter-blue dark:text-twitter-blue-dark" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.33334 2.5L3.75001 7.08333L1.66667 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          )}
        </div>
      </div>
      <span className="ml-3 text-sm text-gray-700 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-white transition-colors duration-200">
        {label}
      </span>
    </label>
  );

  return (
    <div className="filter-popover absolute right-0 mt-2 w-56 rounded-xl shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-10">
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold text-gray-900 dark:text-white">Filters</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
            <X size={20} />
          </button>
        </div>
        <div className="space-y-3">
          {renderFilterOption('likes', 'Likes', filters.likes)}
          {renderFilterOption('bookmarks', 'Bookmarks', filters.bookmarks)}
          {renderFilterOption('user_tweets', 'User Tweets', filters.user_tweets)}
          {renderFilterOption('reddit', 'Reddit Saves', filters.reddit)}
          
          <div className="border-t border-gray-200 dark:border-gray-700 my-3"></div>
          
          {renderFilterOption('withImages', 'With Images', filters.withImages)}
          {renderFilterOption('withVideos', 'With Videos', filters.withVideos)}
          {renderFilterOption('textOnly', 'Text Only', filters.textOnly)}
          {/* {renderFilterOption('over18', 'Include 18+ Content', filters.over18)}
          {renderFilterOption('onlyOver18', 'Only 18+ Content', filters.onlyOver18)} */}
        </div>
      </div>
    </div>
  );
};

export default FilterPopover;