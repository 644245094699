import React from 'react';

const InsightsIcon = ({ size = 24, color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18 12C18 12.7956 17.9006 13.5587 17.7118 14.2934C17.5231 15.028 17.2487 15.7251 16.9 16.364C16.4382 17.2153 15.8031 17.9652 15.0355 18.5746C13.8743 19.5368 12.4719 20.0888 11 20.1836" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 12C6 11.2044 6.09938 10.4413 6.28823 9.70664C6.47708 8.97195 6.75133 8.27492 7.1 7.63604C7.56183 6.78472 8.19694 6.03483 8.96447 5.42542C10.1257 4.46317 11.5281 3.91124 13 3.81641" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 8C3.78542 6.83481 4.79868 5.83254 6 5.05835" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21 16C20.2146 17.1652 19.2013 18.1675 18 18.9417" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default InsightsIcon;
