import React from 'react';

const RedditLogo = ({ size = 583.6 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 583.6 535.9" width={size} height={size * (535.9 / 583.6)}>
    <defs>
      <style>
        {`
          .cls-1 {
            fill: none;
            stroke: #ff4500;
            stroke-miterlimit: 10;
            stroke-width: 20px;
          }

          .cls-2 {
            fill: #ff4500;
          }
        `}
      </style>
    </defs>
    <g>
      <g id="Layer_1">
        <path className="cls-1" d="M556.3,274c-1.2-32.4-28.4-57.7-60.9-56.6-14.4.5-28.1,6.4-38.5,16.3-45.8-31.2-99.8-50.7-155.1-51.9h-25.5c-56,.9-110.5,18.1-156.9,49.5-23.6-22.2-60.8-21.1-83,2.6-22.2,23.6-21.1,60.8,2.6,83,4.6,4.3,9.9,8,15.8,10.6-.4,5.9-.4,11.8,0,17.7,0,90.1,105,163.4,234.5,163.4s234.5-73.2,234.5-163.4c.4-5.9.4-11.8,0-17.7,20.2-10.1,32.9-30.9,32.5-53.5ZM154,314.3c0-22.2,18.1-40.3,40.3-40.3s40.3,18.1,40.3,40.3-18.1,40.3-40.3,40.3c-22.3-.2-40.3-18.1-40.3-40.3ZM378.9,357.5c-22.2,0-38.7-19.6-38.7-41.8s18.1-40.3,40.3-40.3,40.3,18.1,40.3,40.3c.9,22.2-16.4,40.9-38.6,41.8h-3.3Z"/>
        <path className="cls-2" d="M306.9,40.2l-30.2,142h26.2l26.1-125.5,86.1,18.1c2.4,22.1,22.2,38.1,44.3,35.7,22.1-2.4,38.1-22.2,35.7-44.3s-22.2-38.1-44.3-35.7c-12.7,1.3-24.1,8.7-30.4,19.7l-98.6-19.7c-6.7-1.5-13.4,2.7-14.9,9.5"/>
        <path className="cls-2" d="M382.5,430.4c-28.6,21.5-160.8,21.9-193.6-5.6-3.8-4.6-3.1-11.5,1.5-15.3,4-3.3,9.7-3.3,13.8,0,36.4,18.8,143.3,18.3,167.8.8,4.4-4.3,11.6-4.2,15.9.2s4.2,11.6-.2,15.9h0l-5.2,4Z"/>
      </g>
    </g>
  </svg>
);

export default RedditLogo;