import React, { useState, useEffect } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { BookOpen, MessageCircle, Hash } from 'lucide-react';
import { Card, CardContent } from './ui/card';
// import RedditCard from './ui/RedditCard';
// import TweetCard from './ui/TweetCard';
import './Insights.css';
import SearchLink from './ui/SearchLink';
import ProfileImage from './ui/ProfileImage';
import TopicClusters from './ui/TopicClusters';
import TopicTweetsModal from './ui/TopicTweetsModal';
import { cn } from "./utils";

const CHART_COLORS = {
  primary: '#1da1f2',
  secondary: '#64748b',
  accent: '#f59e0b',
  success: '#10b981',
  danger: '#ef4444',
};

const StatCard = ({ title, value, icon: Icon, subtitle }) => (
  <Card>
    <CardContent className="p-6">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-600 dark:text-gray-400">{title}</p>
          <p className="text-2xl font-bold mt-2">{value}</p>
          {subtitle && (
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">{subtitle}</p>
          )}
        </div>
        {Icon && <Icon className="h-8 w-8 text-twitter-blue" />}
      </div>
    </CardContent>
  </Card>
);

const ChartCard = ({ title, children }) => (
  <Card>
    <CardContent className="p-6">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      {children}
    </CardContent>
  </Card>
);

// New Analysis Sections
const ContentOverviewSection = ({ stats }) => (
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
    <StatCard
      title="Total Items"
      value={stats?.total_items || 0}
      icon={BookOpen}
      className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700"
    />
    <StatCard
      title="With Media"
      value={stats?.items_with_media || 0}
      icon={MessageCircle}
      className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700"
    />
    <StatCard
      title="Quote Tweets"
      value={stats?.quote_tweets || 0}
      icon={Hash}
      className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700"
    />
  </div>
);

const EmojiAnalysisSection = ({ emojiData }) => {
  const topEmojis = Object.entries(emojiData || {})
    .sort(([, a], [, b]) => b - a)
    .slice(0, 8);

  return (
    <Card>
      <CardContent className="p-6">
        <h3 className="text-lg font-semibold mb-4">Top Emojis</h3>
        <div className="grid grid-cols-4 gap-4">
          {topEmojis.map(([emoji, count]) => (
            <div key={emoji} className="text-center">
              <SearchLink
                operator="from"
                value={emoji}
                showParens={true}
                pureSearch={true}
                className="hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg p-2 transition-colors duration-200"
              >
                <div className="text-2xl mb-1">{emoji}</div>
                <div className="text-sm text-gray-600 dark:text-gray-400">{count}</div>
              </SearchLink>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

const SentimentOverTimeSection = ({ insightsData }) => {
  return (
    <ChartCard title="Sentiment Over Time">
      <div className="h-96">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={Object.entries(insightsData.sentiment_analysis.temporal_trends)
              .map(([date, data]) => ({
                date,
                positive: data.positive_count,
                negative: data.negative_count
              }))}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" className="text-gray-200 dark:text-gray-700" />
            <XAxis
              dataKey="date"
              tick={{ fill: 'currentColor' }}
              stroke="currentColor"
            />
            <YAxis
              tick={{ fill: 'currentColor' }}
              stroke="currentColor"
            />
            <Tooltip
              contentStyle={{
                backgroundColor: 'var(--bg-color)',
                borderColor: 'var(--border-color)',
                color: 'var(--text-color)'
              }}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="positive"
              name="Positive"
              stroke={CHART_COLORS.success}
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="negative"
              name="Negative"
              stroke={CHART_COLORS.danger}
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </ChartCard>
  );
};

const Insights = ({ allTweets }) => {
  const [insightsData, setInsightsData] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/exports/insights/analysis_summary.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch insights data');
        }
        return response.json();
      })
      .then(data => {
        setInsightsData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching insights data:', error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const handleTopicSelect = (topicId) => {
    setSelectedTopic(selectedTopic === topicId ? null : topicId);
  };

  if (loading) {
    return <div className="mt-8 text-center">Loading insights...</div>;
  }

  if (error) {
    return <div className="mt-8 text-center text-red-500">Error: {error}</div>;
  }

  if (!insightsData) {
    return <div className="mt-8 text-center">No insights data available</div>;
  }

  return (
    <div className="insights mt-8 space-y-8">
      <h2 className="text-3xl font-bold mb-6">Insights</h2>

      {/* Overview Stats */}
      {insightsData?.interaction_analysis?.interaction_stats && (
        <ContentOverviewSection stats={insightsData.interaction_analysis.interaction_stats} />
      )}

      {/* Top Users Section */}
      {insightsData?.interaction_analysis?.top_users && (
        <Card className="mb-6">
          <CardContent>
            <h3 className="text-xl font-semibold mb-4">Top Users</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {insightsData.interaction_analysis.top_users.map((user) => (
                <div
                  key={user.user_info.id}
                  className="flex items-center space-x-3 p-3 rounded-lg bg-gray-50 dark:bg-gray-800"
                >
                  <ProfileImage
                    src={user.user_info.profilePicUrl}
                    alt={user.user_info.name}
                    className="w-10 h-10"
                  />
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <SearchLink
                        operator="from"
                        value={user.user_info.handle}
                        className="text-sm font-medium truncate"
                        showParens={true}
                      >
                        @{user.user_info.handle}
                      </SearchLink>
                    </div>
                    <div className="text-xs text-gray-500 dark:text-gray-400">
                      {user.interaction_count} interactions
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      )}

      {/* Top Subreddits Section */}
      {insightsData?.reddit_analysis?.top_subreddits && (
        <Card className="mb-6">
          <CardContent>
            <h3 className="text-xl font-semibold mb-4">Top Subreddits</h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {insightsData.reddit_analysis.top_subreddits.map(({ subreddit, count }) => (
                <div
                  key={subreddit}
                  className="flex flex-col items-center p-3 rounded-lg bg-gray-50 dark:bg-gray-800"
                >
                  <SearchLink
                    operator="subreddit"
                    value={subreddit}
                    className="text-sm font-medium text-center"
                    showParens={true}
                  >
                    r/{subreddit}
                  </SearchLink>
                  <span className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                    {count} saves
                  </span>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      )}

      {/* Sentiment Over Time */}
      {insightsData?.sentiment_analysis?.temporal_trends && (
        <SentimentOverTimeSection insightsData={insightsData} />
      )}

      {/* Engagement Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Top Users Mentioned */}
        {insightsData?.interaction_analysis?.top_mentions && (
          <Card className="bg-white dark:bg-gray-800">
            <CardContent className="p-6">
              <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">Top Users Mentioned</h3>
              <div className="space-y-3">
                {Object.entries(insightsData.interaction_analysis.top_mentions)
                  .sort(([, a], [, b]) => b - a)
                  .slice(0, 5)
                  .map(([user, count]) => (
                    <div key={user} className="flex justify-between items-center p-3 bg-gray-50 dark:bg-gray-700 rounded">
                      <SearchLink
                        operator="from"
                        value={user}
                        className="text-sm text-twitter-blue dark:text-twitter-blue-dark hover:underline"
                        showParens={true}
                        pureSearch={true}
                      >
                        {user}
                      </SearchLink>
                      <span className="text-sm font-medium text-gray-600 dark:text-gray-300">{count}</span>
                    </div>
                  ))}
              </div>
            </CardContent>
          </Card>
        )}

        {/* Top Hashtags */}
        {insightsData?.interaction_analysis?.top_hashtags && (
          <Card className="bg-white dark:bg-gray-800">
            <CardContent className="p-6">
              <h3 className="text-lg font-semibold mb-4 text-gray-900 dark:text-white">Top Hashtags</h3>
              <div className="space-y-3">
                {Object.entries(insightsData.interaction_analysis.top_hashtags)
                  .filter(([tag]) => tag.trim() !== '')
                  .sort(([, a], [, b]) => b - a)
                  .slice(0, 5)
                  .map(([tag, count]) => (
                    <div key={tag} className="flex justify-between items-center p-3 bg-gray-50 dark:bg-gray-700 rounded">
                      <span className="text-sm text-gray-800 dark:text-gray-200">
                        <SearchLink
                          operator="from"
                          value={"#" + tag}
                          showParens={true}
                          pureSearch={true}
                        >
                          #{tag}
                        </SearchLink>
                      </span>
                      <span className="text-sm font-medium text-gray-600 dark:text-gray-300">{count}</span>
                    </div>
                  ))}
              </div>
            </CardContent>
          </Card>
        )}
      </div>

      {/* Top Emojis */}

      {insightsData?.emoji_analysis && Object.keys(insightsData.emoji_analysis).length > 0 && (
        <Card className="bg-white dark:bg-gray-800">
          <EmojiAnalysisSection emojiData={insightsData.emoji_analysis} />
        </Card>
      )}

      {/* Topic Clusters */}
      {insightsData?.semantic_clusters?.clusters && (
        <>
          <TopicClusters
            clusters={insightsData.semantic_clusters.clusters}
            selectedTopic={selectedTopic}
            onTopicSelect={handleTopicSelect}
          />
          
          <TopicTweetsModal
            isOpen={selectedTopic !== null}
            onClose={() => setSelectedTopic(null)}
            topic={selectedTopic ? insightsData.semantic_clusters.clusters[selectedTopic] : null}
            allTweets={allTweets}
          />
        </>
      )}
    </div>
  );
};

export default Insights;